.divFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2rem;
    font-size: 1rem;
    background-color: var(--blueColor);
    color: var(--bs-dark);
    padding-right: 5%;
    padding-left: 5%;
}
    .divFooter label {
        font-size: 0.8rem;
        font-weight: normal;
        color: var(--bs-white);
    }

@media (max-width: 768px) {
    .divFooter label {
        font-size: 1rem;
    }
}
