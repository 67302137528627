.loginHtml {
    background-image: url(../public/images/login-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vH;
    width: 100vW;
}

.loginContainer {
    padding: 1rem 0.5rem 1rem 0.5rem;
    margin: 5% auto;
    height: 80%;
    min-width: fit-content;
    width: 50%;
    max-width: 800px;
    background-color: rgba(255,255,255, .8);
    backdrop-filter: blur(5px);
    text-align: center;
    vertical-align: middle;
    border: 2px solid var(--blueColor);
}

    .loginContainer h1 {
        font-size: 2rem;
        margin: 2px 0px;
        color: var(--blueColor);
        font-weight: bold;
        text-shadow: 2px 2px 3px var(--bs-gray-600);
    }

    .loginContainer h2 {
        font-size: 1.6rem;
        margin: 2px 0px;
        color: var(--blueColor);
        font-weight: bold;
        text-shadow: 2px 2px 3px var(--bs-gray-600);
    }

    .loginContainer label {
        font-size: 0.8rem;
        margin: 2px 0px;
        color: var(--blueColor);
    }

    .loginContainer p {
        font-size: 0.8rem;
        margin: 0.2rem auto;
        color: var(--bs-dark);
        width: 100%;
        max-width: 450px;
        min-width: 100px;
        text-align: justify;
    }

    .loginContainer button[type=submit] {
        background-color: var(--blueColor);
        border: 1px solid var(--blueColor);
        margin: 10px 0px 5px 0px;
    }

    .loginContainer .loginSkipButton {
        background-color: var(--bs-success);
        border: 1px solid var(--bs-success);
        margin: 10px 0px 5px 0px;
    }

    .loginContainer .recoverPassword {
        background-color: transparent;
        min-width: 100px;
        width: 100%;
        max-width: 250px;
        padding: 5px;
        color: var(--blueColor);
        cursor: pointer;
    }

    .loginContainer .fixWhatsapp {
        background-color: transparent;
        min-width: 100px;
        width: 100%;
        max-width: 250px;
        padding: 5px;
        color: var(--bs-success);
        cursor: pointer;
        font-size: 0.8rem;
    }

.whatsappContactDiv {
    background-color: rgba(125,125,125, 0.25);
    border-radius: 1rem;
    border: 0.2rem solid var(--bs-green);
    margin: 5px auto;
    text-align: center;
    vertical-align: central;
    padding: 1rem;
    width: fit-content;
}

.whatsappContactIcon {
    width: 25%;
    max-width: 50px;
    height: auto;
    opacity: 0;
    animation: bounce-in 2.5s linear;
    -webkit-animation-fill-mode: forwards;
    cursor: pointer;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        -webkit-transform: rotateZ(0deg);
    }

    to {
        opacity: 1;
        -webkit-transform: rotateZ(360deg);
    }
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }

    25% {
        transform: scale(1.1);
    }

    50% {
        transform: scale(0.9);
    }

    75% {
        transform: scale(1.2);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@media only screen and (max-width: 768px) {
    .loginContainer {
        margin: 5% auto;
        width: 90%;
        max-width: 700px;
    }

        .loginContainer input[type=text], .loginContainer input[type=password] {
            padding: 10px 5px;
            font-size: 1.5rem;
            width: 90%;
            max-width: 350px;
        }

        .loginContainer h1 {
            font-size: 2.5rem;
        }

        .loginContainer h2 {
            font-size: 2rem;
        }

        .loginContainer label {
            font-size: 1.5rem;
        }

        .loginContainer p {
            font-size: 1.2rem;
            width: 90%;
            max-width: 350px;
        }

        .loginContainer button[type=submit], .loginContainer .loginSkipButton {
            font-size: 1.5rem;
            width: 90%;
            max-width: 350px;
        }

        .loginContainer select {
            font-size: 1.5rem;
            width: 90%;
            max-width: 350px;
        }

        .loginContainer .recoverPassword, .loginContainer .fixWhatsapp {
            width: 90%;
            max-width: 350px;
        }

        .loginContainer .fixWhatsapp {
            font-size: 1.2rem;
        }
}
