:root {
    --blueColor: #3a3a5b;
    --btn-blueColor: #3a3a5b;
}

a {
    color: #0366d6;
}

html {
    background-color: var(--bs-white);
    height: 100vH;
    width: 100vW;
    overflow-y: hidden;
    overflow-x: hidden;
}

.main-data {
    height: 100vH;
    width: 100vW;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    border: 0px none transparent;
}

    .main-data .container-data {
        height: calc(100% - 2rem - 60px);
    }

        .main-data .container-data .container {
            overflow-y: auto;
            height: 100%;
            min-height: 100%;
            max-height: 100%;
            margin: 0px;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }

.mainContainer {
    padding: 1rem 0.5rem 1rem 0.5rem;
    margin: 0px;
    width: 100%;
    background-color: transparent;
    text-align: center;
    vertical-align: middle;
    border: 0px none transparent;
}

    .mainContainer h1 {
        font-size: 2rem;
        margin: 2px 0px;
        color: var(--blueColor);
        font-weight: bold;
        text-shadow: 2px 2px 3px var(--bs-gray-600);
    }

    .mainContainer h2 {
        font-size: 1.6rem;
        margin: 2px 0px;
        color: var(--blueColor);
        font-weight: bold;
        text-shadow: 2px 2px 3px var(--bs-gray-600);
    }

    .mainContainer label {
        font-size: 1rem;
        margin: 2px 0px;
        color: var(--blueColor);
    }

    .mainContainer p {
        font-size: 1rem;
        margin: 0.2rem auto;
        color: var(--bs-dark);
        width: 100%;
        max-width: 450px;
        min-width: 100px;
        text-align: justify;
    }

::-webkit-scrollbar {
    width: 0.25rem;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: var(--blueColor);
}

    ::-webkit-scrollbar-thumb:hover {
        background: var(--blueColor);
    }

body {
    background-color: transparent;
}

label {
    margin: 5px 0px;
    font-size: 1rem;
    font-weight: bold;
    color: var(--bs-dark);
}

input[type=text], input[type=password], input[type=number], input[type=email], input[type=datetime-local], textarea {
    margin: 5px 0px;
    padding: 5px;
    font-size: 1rem;
    font-weight: normal;
    min-width: 100px;
    width: 100%;
    max-width: 250px;
    background-color: var(--bs-white);
    border: 1px solid var(--bs-dark);
    color: var(--bs-dark);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="checkbox"] {
    margin: 5px 5px;
    font-size: 1rem;
}

textarea:focus, input:focus {
    outline: none;
}

button {
    margin: 5px 0px;
    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    min-width: 100px;
    width: 100%;
    max-width: 250px;
    background-color: var(--blueColor);
    border: 1px solid var(--blueColor);
    color: var(--bs-white);
}

select {
    margin: 5px 0px;
    padding: 5px;
    font-size: 1rem;
    font-weight: normal;
    min-width: 100px;
    width: 100%;
    max-width: 250px;
    background-color: var(--bs-white);
    border: 1px solid var(--bs-dark);
    color: var(--bs-dark);
}

.background-azul {
    background-color: var(--blueColor);
}

.text-azul {
    color: var(--blueColor);
}

.border-azul {
    border-color: var(--blueColor);
}

.alert {
    margin: 5px 10px;
}

.alert-dismissible .btn-close {
    margin: 0px;
    min-width: unset;
}

.myalert {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    margin: 0px;
    z-index: 10000000;
}

.myconfirm {
    background-color: rgba(255,255,255, 0.6);
    backdrop-filter: blur(5px);
    border-bottom: 0px none transparent;
    text-align: center;
    padding: 0px;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
}

    .myconfirm .floatingConfirmationBox {
        background-color: var(--bs-dark-bg-subtle);
        text-align: center;
        padding: 0px;
        height: fit-content;
        width: 80%;
        margin: 3rem auto 3rem auto;
        border: 1px solid var(--blueColor);
    }

    .myconfirm .floatingConfirmationBoxTitle {
        background-color: var(--blueColor);
        width: 100%;
        position: relative;
        top: 0px;
        left: 0px;
        color: white;
        font-size: 1.2rem;
        padding: 1rem;
    }

    .myconfirm .floatingConfirmationBoxMessage {
        background-color: var(--bs-gray-200);
        width: 100%;
        position: relative;
        top: 0px;
        left: 0px;
        color: var(--blueColor);
        font-size: 1rem;
        font-weight: normal;
        padding: 2rem 2rem;
    }

    .myconfirm .floatingConfirmationBoxFooter {
        background-color: var(--bs-gray-200);
        width: 100%;
        position: relative;
        bottom: 0px;
        left: 0px;
        padding: 1rem;
    }

    .myconfirm .btn-close-white {
        float: right;
        min-width: max-content;
        width: max-content;
        max-width: max-content;
    }

    .myconfirm .confirm-btn {
        margin: 5px 5px;
        padding: 5px;
        font-size: 1rem;
        font-weight: bold;
        min-width: 50px;
        width: 50%;
        max-width: 200px;
        background-color: var(--blueColor);
        border: 1px solid var(--blueColor);
        color: var(--bs-white);
    }

.spinner-border {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: calc(50% - 2rem);
    left: calc(50% - 2rem);
    font-size: 2rem;
    background-color: transparent;
}

.single-input {
    border: 0px none transparent !important;
    border-bottom: 1px solid var(--blueColor) !important;
    padding: 0px 5px 1px 5px !important;
    font-size: 1rem !important;
}

.update-input-data-btn {
    margin: 2px 10px;
    color: green;
    font-size: 1rem;
    cursor: pointer;
}

.floatingDiv {
    float: left;
}

.homeLineChart, .homeBarChart, .homePieChart, .homeLineChartGroup, .homeBarChartGroup {
    height: 50vh;
    min-height: 400px;
}

.homeLineChart {
    width: 100%;
}

.homeBarChart {
    width: 60%;
}

.homePieChart {
    width: 40%;
}

.homeLineChartGroup {
    width: 60%;
}

.homeBarChartGroup {
    width: 40%;
}

.edit-table {
    margin: auto auto;
    display: table;
}

.configCardHolder {
    width: calc(100% / 2);
}

    .configCardHolder .card {
        width: 75%;
        margin: 15px auto;
        position: static;
        height: 150px;
        cursor: pointer;
    }

        .configCardHolder .card .card-title {
            border-bottom: var(--blueColor) solid 2px;
        }

table {
    border-color: var(--blueColor) !important;
    border-width: 2px !important;
    border-style: solid !important;
}

thead {
    background-color: var(--blueColor) !important;
    border: 1px solid var(--blueColor) !important;
    color: var(--bs-white) !important;
    font-size: 1rem !important;
}

tbody {
    background-color: var(--bs-gray-100) !important;
    border: 1px solid var(--bs-gray-100) !important;
    color: var(--blueColor) !important;
    font-size: 0.9rem !important;
}

    tbody tr {
        cursor: pointer !important;
    }

tfoot {
    background-color: var(--blueColor) !important;
    border: 1px solid var(--blueColor) !important;
    color: var(--bs-white) !important;
    font-size: 0.8rem !important;
}

.report_table, .report_table tr, .report_table td, .report_table tbody, .report_table tbody tr, .report_table tfoot {
    cursor: default;
    font-size: 0.8rem;
}

.mini_report_table, .mini_report_table tr, .mini_report_table td, .mini_report_table tbody, .mini_report_table tbody tr, .mini_report_table tfoot {
    cursor: default;
    font-size: 0.7rem;
}

.mini_report_table_icon {
    font-size: 0.6rem;
    margin: 2px 5px;
}

.first_Left_then_Center {
    text-align: left !important;
}

.first_Right_then_Center {
    text-align: right !important;
}

.first_Left_then_Right {
    text-align: left !important;
}

.first_Right_then_Left {
    text-align: right !important;
}

.first_Center_then_Right {
    text-align: center !important;
}

.first_Center_then_Left {
    text-align: center !important;
}

.first_Center_then_Center {
    text-align: center !important;
}

.mini-table {
    max-width: 600px;
    margin: 10px auto;
}

.recharts-wrapper {
    position: static !important;
}

.infobox p {
    text-align: left;
    margin-left: 5px;
}

.accordion-header .accordion-button {
    background-color: transparent;
    box-shadow: none;
    max-width: none;
}

.reportHeader {
    text-align: center;
    width: 100%;
    border: 2px solid var(--blueColor);
    margin: 0px 0px 10px 0px;
    padding: 0px;
}

    .reportHeader h2, .reportHeader h4 {
        text-align: left;
        margin: 0px;
        padding: 0px 0px 10px 10px;
        background-color: var(--bs-gray-500);
    }

    .reportHeader p {
        text-align: center;
        margin-bottom: 0px;
    }

        .reportHeader p label, .reportHeader div label {
            text-align: right;
            margin: 0px 10px 0px 0px;
            min-width: 5rem;
        }

        .reportHeader p button {
            margin: 5px 10px;
        }

.floatingMessage {
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: #3a3a5bd4;
    position: absolute;
    top: 0;
    left: 0;
}

.innerMsg {
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    background-color: white;
    border: 0.5rem solid var(--blueColor);
    position: relative;
    border-radius: 1.2rem 1.2rem 1.2rem 1.2rem;
    padding: 2rem;
}

@media only screen and (max-width: 768px) {
    .floatingDiv {
        float: none !important;
        width: 100% !important;
    }

    .homeLineChart, .homeBarChart, .homePieChart, .homeLineChartGroup, .homeBarChartGroup {
        height: 30vh;
    }

    .report_table, .report_table tr, .report_table td, .report_table tbody, .report_table tbody tr, .report_table tfoot {
        font-size: 2vw;
        cursor: default;
    }

    .mini_report_table, .mini_report_table tr, .mini_report_table td, .mini_report_table tbody, .mini_report_table tbody tr, .mini_report_table tfoot {
        font-size: 2vw;
        cursor: default;
    }

    .mini_report_table_icon {
        font-size: 1.5vw;
        margin: 2px 5px;
    }

    .first_Left_then_Center {
        text-align: center !important;
    }

    .first_Right_then_Center {
        text-align: center !important;
    }

    .first_Left_then_Right {
        text-align: right !important;
    }

    .first_Right_then_Left {
        text-align: left !important;
    }

    .first_Center_then_Right {
        text-align: right !important;
    }

    .first_Center_then_Left {
        text-align: left !important;
    }

    .first_Center_then_Center {
        text-align: center !important;
    }

    .labelChartsLocation {
        margin-top: 60px;
        margin-bottom: 0px;
    }

    .myconfirm .floatingConfirmationBoxTitle {
        font-size: 1.4rem;
    }

    .myconfirm .floatingConfirmationBoxMessage {
        font-size: 1.2rem;
    }

    .mainContainer h1 {
        font-size: 2.5rem;
    }

    .mainContainer h2 {
        font-size: 2rem;
    }

    .mainContainer label {
        font-size: 1.5rem;
    }

    .mainContainer p {
        font-size: 1.5rem;
    }
}
/*-------------------*/


/*

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.confirm-button {
    background-color: var(--bs-success);
    border: 1px solid var(--bs-success);
    color: var(--bs-white);
}

.delete-button {
    background-color: var(--bs-danger);
    border: 1px solid var(--bs-danger);
    color: var(--bs-white);
}

.cancel-button {
    background-color: var(--bs-warning);
    border: 1px solid var(--bs-warning);
    color: var(--bs-white);
}

.edit-table {
    margin: auto auto;
    display: table;
}

table {
    border-color: var(--bs-dark) !important;
    border-width: 2px !important;
    border-style: solid !important;
}

thead {
    background-color: var(--bs-teal);
    border: 1px solid var(--bs-teal);
    color: var(--bs-dark);
    font-size: 1rem;
}

tbody {
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-100);
    color: var(--bs-dark);
    font-size: 0.9rem;
    cursor: pointer;
}

tfoot {
    background-color: var(--bs-teal);
    border: 1px solid var(--bs-teal);
    color: var(--bs-dark);
    font-size: 0.8rem;
}

.inner-table, .total-inner-table {
    margin: 0px 0px;
    padding: 0px;
    border: 0px none transparent !important;
}

    .total-inner-table tr td, .total-inner-table tr th {
        box-shadow: none;
    }

.inner-table thead {
    background-color: var(--bs-gray-600);
    border: 1px solid var(--bs-gray-600);
    color: var(--bs-white);
    font-size: 0.8rem;
    padding: 2px;
    cursor: default;
}

    .inner-table tbody {
        background-color: var(--bs-gray-100);
        border: 1px solid var(--bs-gray-100);
        color: var(--bs-dark);
        font-size: 0.75rem;
        padding: 2px;
        cursor: default;
    }

    .inner-table tfoot {
        background-color: var(--bs-gray-500);
        border: 1px solid var(--bs-gray-500);
        color: var(--bs-dark);
        font-size: 0.75rem;
        cursor: default;
    }

    .total-inner-table tbody {
        background-color: transparent;
        border: 0px none transparent;
        color: var(--bs-dark);
        font-size: 0.85rem;
        padding: 2px;
        cursor: default;
    }

*/
