header {
    background-color: var(--blueColor);
    backdrop-filter: blur(5px);
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
  color: var(--bs-white);
}

.navbar-toggler {
    width: 3.5rem;
    min-width: unset;
    max-width: unset;
    height: 3.5rem;
    min-height: unset;
    max-height: unset;
    margin: 5px 0px;
    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    border: 0px none transparent;
    color: var(--bs-white);
}

.navbar-toggler-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--bs-white);
}

@media (min-width: 768px) {

}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
